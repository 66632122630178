// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: true,
  debug: true,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  privateKey:{key:"MIIEpAIBAAKCAQEA2ELf2438VrzQVq/86rlLQhOFI6NYNpragm52m+89qVp4NAgT6QrSHQtoIFQbTRLBIfa5GTTb0LVJ+GVCHBoEv63cM3W3A1DTyRXBKwgI9MgEVrT9Zme7lH2vQ76ZwYfzEcgcgmNFMZiRqZbNG2JeFkVd7XkuQC4Qqo/gQD75QfhWIGVwwXejNCAmuJnsSOS7uTjJQi2Nhz4Du/JSup19bsofLymJ4oM4ZYwenFqljOGr15/XlkPo7mMQEtpz6JlzvRwxHCD5OmhWItSQFtXnd+AFsh9pWn2uNxTpzt8oRNaNlXGuTAgO2ewNa1CRLPqveR8rk4cnQWUAee2c3WxKnQIDAQABAoIBACgqY4FXoaJKdzdigEroFz4yeevgjqW1pRfMhGL50C4AC4s+ZNRHmjaiw7gdZuStswkn1cEEvD7LLO3hgKHPg0pGdDtqhY8sF+mlDLd9SoyMCaJBAz9dzM0Let3OLKhxI4gN7p2Y6mUHvSYOSBHp9yeTwpo0g46P/7BuAGxuRcUmjvsJtVufL69oYX8sSoQH9pTOMSgV/B/Xr0YLw1asx3hDF2FGinwruOGAKIW8KnGcVnUIn8JsT7440HGbZK+bbh8pPV0pVDvgoCnYARx8rWrtKriLDSFNBTkxnmQS6Yn4P2u8E7D9srsFKSlRIr0a3nJlyWa9n/RbhBNKd98a4YECgYEA9hhCfEXFVQuT8rdwiFEd4zb8Z0tDdg5/WCq4U4pmWW4xp7kbttHVlCh0JxZ206MSop+4tFGVJX6ivP6+JaHPpgASbGcG23tdAKyc9Bkt2aRxzakO6tSH1kWORomOQWILHRd7kFkzLYMmQRfKG9LpXi6TiglmYMBo0N65+kuShWkCgYEA4Pc2YVDywvT54uo9zEd6PBl7Gbw2I3alSEKr9eIO93obH8rl8/xw9qhrIF6A92WV/wKV2ponm+1tEPzPiIOGojCkjU0QU/uun9zZaJQfeZoAUE/yV2WGqHLwOf3dRttamF5slKFIZKWaN15UUTbIzx6KtbgVBmYo2Tt+x0zkcRUCgYEA3VSIRziA97IIB92Pqio6VSYY79WzALSdN3CEgy4QSxJeOlizYkNqcWvIu3bqvAEIO5anChXtq+EvAAekgDf9h3C9IR2v/yn+RSL5rlgO8pWBfNQ1hGJNIRtBkJMajBdfo3SBPxvaeW5gs15v+YJBPOFq9FTr7Mxf+Kq4XTImvzECgYAJLq3+jOJLyNoGRA9fri80Esoug7sl+WrHp0kDkUWRHl/LDWoTamNl/fvZZ2IWNwRHU2CweohRWgCQp/EEnnG5XwjoZ5PaCbTX+5i4Jwud2AZZQNogbRlw+LgLdew5PyzUeU4vRuIAka3YwrXoVrcdlR6OSkQ5baQPMdl74wyT/QKBgQDilGQIkopjj3436p5LqHlmpAYqfgr2K8qH3MVIjNhZr4SFJpPSlLLaeQEbypNhhMk6hsBuNAKeY21yLQzUcZTkZtuMHksinqSj4j8Bj5S3MOWzu4bXmux5jlxOTYWpalxc0h4kdGEpPmNlXGS2djHJvPlKLnwtqG3c81q4Fw2TGw=="},
  encryptionKey : "k5jRDaLLah7u6nI9YgEwOQ==",
  cookieDomain:".io-netgate.com",
  checkCookie:"expireCookie",
  instanceCookie:"instanceCookie",
  loginCookie: "loginCookie",
  userRightsCookie: "userRightsCookie",
  tokenExpireTime:"cookieCreatedTime",
  userInfo: "userInfo",
  NGT18URL:'https://dfh56.api.io-netgate.com/api/v1/netgateAPI/',
  domain:'.io-netgate.com',
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  cookieConsent: "cookieConsent",
  AUTHURL:'https://dfh56.api.io-netgate.com/api/v1/main/',
  NETGATE_URL: 'https://api.io-netgate.com/',
  SUB_DOMAIN:".io-netgate.com",
  SESSION_EXPIRY : 30000 * 60 * 30,
  color: '',
  MSAL: '{"hilcona":{"clientId":"91b18830-ca7a-4203-9e92-47b5a82827d5","tenantId":"e60b3f74-bc70-47d9-b249-d3e248192780"},"repower":{"clientId":"8095cded-14e5-48a6-9eaf-363e0acff11d","tenantId":"6dc8f987-c886-4792-acef-76455cc290b9"},"io-market":{"clientId":"7efffc4b-9555-4256-8cb3-4a74137d808d","tenantId":"659d872a-a337-4f81-8328-258203b0324b"},"iomarketin":{"clientId":"a1fbb16d-718f-4c02-95fd-ec381a715c1e","tenantId":"04d6c97e-08fa-4eb8-8437-ad2e68d91ddb"}}',

};

